<template>
  <vx-tooltip id="kona-help-tooltip" :text="text" :position="position">
    <feather-icon :icon="icon" svgClasses="h-5 w-5" />
  </vx-tooltip>
</template>

<script>
export default {
  name: 'KonaHelpTooltip',
  props: {
    text: {
      type: String,
      required: true
    },
    position: {
      default: 'top',
      type: String
    },
    icon: {
      type: String,
      default: 'InfoIcon'
    }
  }
}
</script>

<style lang="scss">
#kona-help-tooltip {
  height: fit-content;
  .feather-icon {
    cursor: pointer;
    padding: 5px;
  }
}
</style>
